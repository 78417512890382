html {
  overflow: hidden;
}

body {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  overflow-x: hidden;
}

.cardsContainer p {
  margin-top: 0.5dvw;
  margin-bottom: 0.5dvw;
  font-size: min(1.6dvh, 0.9dvw);
}

.cardsContainer {
  width: calc(35% - 3.5rem);
}

.mapContainer {
  width: calc(65% + 3.5rem);
  border-width: 0 0 0.25dvh 0.25dvh;
  border-radius: 0 0 0 4dvh;
  height: calc(100vh - 1dvh);
  overflow: hidden;
  position: sticky;
  right: 0em;
  top: 0rem;
  bottom: 1dvh;
}

.mapContainerMobile {
  width: 100%;
  height: calc(100dvh - 50px - 40px);
  overflow: hidden;
  position: absolute;
  z-index: 0;
}

.mapbox-improve-map {
  display: none !important;
}

.mapboxgl-control-container {
  display: none !important;
}

/* Estilos nuevos para leyendas iniciales */

.legend-container {
  width: min-content;
  border-width: 0.08rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  bottom: 7dvh;
  left: 20px;
  background: rgba(255, 255, 255, 0.9);
  padding: min(0.4dvh, 0.2dvw) min(1.6dvh, 0.8dvw);
  box-shadow: 0 2px 8px rgba(50, 50, 50, 0.2);
  z-index: 10;
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  bottom: 70px;
  left: 10px;
  z-index: 10;
  font-size: x-small;
}

.legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.legend-color {
  margin-right: 0.2dvw;
  /* height: min(1.6dvh, 0.8dvw);
  width: min(1.6dvh, 0.8dvw); */
}

.legend-numbers {
  display: flex;
  flex-grow: 1;
}

.legend-label {
  flex: 1;
  text-align: right;
  font-size: min(1.6dvh, 0.8dvw);
  color: #333;
  font-family: "Arial", Courier, monospace;
}

.legend-label:last-child {
  text-align: right;
}

.custom-legend-label {
  flex: 1;
  text-align: left !important;
  line-height: normal;
  padding: 0.2dvw;
  font-size: min(1.6dvh, 0.8dvw);
  color: #333;
  font-family: "Arial", Courier, monospace;
}

.legend-dash {
  padding: 0 min(1dvh, 0.5dvw);
  flex-shrink: 0;
  font-size: min(1.6dvh, 0.8dvw);
  font-family: "Arial", Courier, monospace;
}

td {
  text-align: right !important;
}

.legendLinear {
  font-size: 0.6rem;
  color: #333;
  font-family: "Arial", Courier, monospace;
}

.tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  pointer-events: none;
}

.tooltip-content {
  font-size: "0.8rem";
}

.tooltip-label {
  text-align: left;
  color: #333;
}

.tooltip-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  padding: 5px 10px;
  box-shadow: 0 2px 8px rgba(50, 50, 50, 0.3);
  z-index: 10;
  width: 300px;
  border-radius: 5px;
}

.tooltip-container::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent;
}

.tooltip-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  padding: 5px 10px;
  box-shadow: 0 2px 8px rgba(50, 50, 50, 0.3);
  z-index: 10;
  width: 350px;
  border-radius: 5px;
}

.button-popup {
  position: absolute !important;
  z-index: 10;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* Estilos para el contenedor del video dentro del popup */
.video-container {
  position: relative;
  padding-top: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.subtitle,
.description {
  margin-bottom: 0.2rem;
}

.modal-header {
  margin-bottom: -30px;
}

.fullscreenMessage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 999;
  padding: 4%;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
}

.legend {
  position: absolute;
  top: 80px;
  right: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  z-index: 10;
}

.timeline-selector {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 0.75dvh;
  margin-bottom: 1.25dvh;
  padding-bottom: 10px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 10px;
  background: #f4f4f8;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 100px;
}

.timeline-selector .button {
  padding: 10px;
  font-size: 0.6rem;
  color: #333;
  background-color: #f4f4f8;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 70px;
  flex: 1;
  text-align: center;
}

.timeline-selector .button:hover {
  background-color: #ffffff;
  color: #007bff;
}

.timeline-selector .active {
  color: #fff;
  background-color: #5DADE2;
  border-color: #5DADE2;
}
@media (max-width: 600px) {
  .timeline-selector {
    justify-content: center;
  }

  .timeline-selector .button {
    font-size: 9px; 
    padding: 8px;
    min-width: 60px;
  }
}