.cardSection {
  display: flex;
  left: 0px;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  width: calc(100% - 0.4rem);
  padding-top: 8dvh;
  padding-bottom: 1dvh;
  height: 100dvh;
}

.card {
  border: 0.25dvh solid;
  padding: 7dvh 1dvw 2dvh 1dvw;
  border-radius: 4dvh;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
}

.highlightAccent {
  font-family: Inter;
  color: rgb(245, 159, 0);
  font-weight: 600;
  cursor: pointer;
  padding: 0.1rem 0.2rem;
  border-radius: 0.4rem;
}

.highlightAccent:hover {
  background: orange;
  color: white;
}

.highlightSubcenters {
  font-family: Inter;
  color: rgb(85, 0, 255);
  font-weight: 600;
  cursor: pointer;
  padding: 0.1rem 0.2rem;
  border-radius: 0.4rem;
}

.highlightSubcenters:hover {
  background: rgb(117, 62, 255);
  color: white;
}

.highlightTransport {
  font-family: Inter;
  color: rgb(255, 105, 180, 128);
  font-weight: 600;
  cursor: pointer;
  padding: 0.1rem 0.2rem;
  border-radius: 0.4rem;
}

.highlightEmployments {
  font-family: Inter;
  color: rgb(64, 224, 208, 128);
  font-weight: 600;
  cursor: pointer;
  padding: 0.1rem 0.2rem;
  border-radius: 0.4rem;
}

.highlightCenters {
  font-family: Inter;
  color: rgb(0, 89, 255);
  font-weight: 600;
  cursor: pointer;
  padding: 0.1rem 0.2rem;
  border-radius: 0.4rem;
}

.highlightTransport { 
  font-family: Inter;
  color: rgb(255, 105, 180, 128);
  font-weight: 600;
  cursor: pointer;
  padding: 0.1rem 0.2rem;
  border-radius: 0.4rem;
}

.highlightEmployments { 
  font-family: Inter;
  color: rgb(64, 224, 208, 128);
  font-weight: 600;
  cursor: pointer;
  padding: 0.1rem 0.2rem;
  border-radius: 0.4rem;
}

.highlightCenters:hover {
  background: rgb(62, 62, 255);
  color: white;
}

.response {
  font-family: Inter;
  margin-bottom: 0.3rem;
  font-weight: 600;
}

.context {
  font-family: Inter;
  text-align: left;
  font-style: italic;
  font-weight: 600;
}
