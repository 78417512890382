.headerContainer {
  position: sticky;
  top: 0;
  left: 0;
  margin: 0;
  width: calc(100% - 0.4rem);
  height: 14dvh;
  transition: background-color 0.2s linear;
  z-index: 1;
  border-radius: 0px 0px 4dvh 4dvh;
}

.headerImage {
  height: 100%;
}

.titleContainer {
  transition: background-color 0.2s linear;
  letter-spacing: -0.8px;
  border-radius: 0 0 4dvh 4dvh;
  width: 100%;
  height: 6dvh;
  line-height: 6dvh;
}

.title {
  transition: border-color 0.2s linear;
  border: 0.25dvh solid;
  border-radius: 4dvh;
  font-family: Inter;
  text-transform: uppercase;
  background-color: white;
  text-align: center;
  line-height: 6dvh !important;
  font-weight: 700 !important;
  height: 100%;
}

.titleMobile {
  transition: background-color 0.2s linear;
  letter-spacing: -0.8px;
  padding: 1rem;
  border-radius: 0.4rem 0.4rem 0 0;
  font-family: Inter;
  text-transform: uppercase;
  font-size: 1.3rem;
  background-color: white;
  text-align: center;
  line-height: 1.6rem;
  font-weight: 700;
  max-height: 50px;
  min-height: 50px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  z-index: 2;
}

.react-modal-sheet-container {
  height: max(50px, calc(100% - env(safe-area-inset-top))) !important;
}

.react-modal-sheet-scroller {
  padding-bottom: 50px !important;
  height: calc(100% - env(safe-area-inset-top)) !important;
}
