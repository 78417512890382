.sidebar {
  width: 3.5dvw;
  height: 100vh;
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarMobile {
  height: 40px;
  width: 100vw;
  color: white;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  overflow-x: scroll;
}

.sidebarContainer {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebarContainerMobile {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.sidebarItem {
  border-radius: 0px !important;
  width: 100%;
}
